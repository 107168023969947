import React from 'react';
import AccordionItem from './FaqButtonItem';
import styled from 'styled-components';

const Wrapper = styled.div`
    max-width: 1200px;
    margin-bottom: 170px;
    margin: 0 auto;

    @media (max-width: 769px) {
        margin: 0 20px 100px 20px;
    }

    @media (max-width: 550px) {
        margin-bottom: 200px;
    }
`;

const Title = styled.h3`
    font-size: 35px;
    font-weight: 900;
    text-align: left;
    color: #27A04A;
    margin-bottom: 16px;

    @media (max-width: 769px) {
        font-size: 30px;
    }
`;

const Accordion = ({ data }) => {
    return (
        <Wrapper>
            <Title>Frequently Asked Questions</Title>
            <ul className="accordion-list">
                {data.map((accordionData, index) => {
                    return (
                        <li className="accordion-list__item" key={accordionData.title}>
                            <AccordionItem data={accordionData} index={index} />
                        </li>
                    );
                })}
               
            </ul> 
        </Wrapper>
    );
};

export default Accordion;

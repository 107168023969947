import * as React from "react"
import { graphql } from 'gatsby'
import Header from "../../templates/Header/index";
import HeroSection from "./sections/hero-section";
import SecondSection from './sections/second-section';
import ThirdSection from './sections/third-section';
import MinimalFooter from '../../templates/MinimalFooter/index';
import Helmet from 'react-helmet';
import Faq from '../../molecules/FaqButton/FaqButton';
import FaqContent from './sections/faq-content';

const HeaderProperties = () => {
  return (
      <Helmet>
          <title>Collexin: Increase collections, decrease frustrations.</title>
          <meta
              name="description"
              content="Collexin is a digital platform that eliminates the headaches and costly process of traditional debt collection. Using technology, Collexin delivers a streamlined, personalized experience, that drives customers to take control, and collectors to drive up returns."
          />
          <meta
              property="og:url"
              content="https://www.collexin.com/"
          />
          <meta
              property="og:title"
              content="Collexin: Increase collections, decrease frustrations."
          />
          <meta
              property="og:description"
              content="Collexin is a digital platform that eliminates the headaches and costly process of traditional debt collection. Using technology, Collexin delivers a streamlined, personalized experience, that drives customers to take control, and collectors to drive up returns."
          />
      </Helmet>
  )
};

const ConsumerHomeTemplate = ({ data }) => {
  return (

    <React.Fragment>
      {data !== null ?
        <>
          <Header />
          <HeroSection
            title={data.heroSectionTitle}
            paragraph={data.heroSectionDescription}
            heroImage={data.heroImage}
            heroImageAlt={data.heroImageAlt}
          />

          <SecondSection 
            title={data.secondSectionTitle}
            subTitle={data.secondSectionSubTitle}
            description={data.secondDescription}
            sectionPoints={data.secondSection}
            image={data.secondSectionImage}
            imageAlt={data.secondSectionImageAlt}
          />

          <ThirdSection 
            title={data.thirdSectionTitle}
            subTitle={data.thirdSectionSubTitle}
            description={data.thirdDescription}
            sectionPoints={data.thirdSection}
            image={data.thirdSectionImage}
            imageAlt={data.thirdSectionImageAlt}
          />

          <Faq data={FaqContent} />
          <div style={{height: '100px'}} />
          <MinimalFooter />
        </>
        : null}
    </React.Fragment>
  );
};

const ConsumerHome = ({ data }) => {
  const {
    markdownRemark: { frontmatter },
  } = data;

  return (
    /*  <Layout> */
    <>
    <HeaderProperties />
    <ConsumerHomeTemplate data={frontmatter} />
    </>
    /* </Layout> */
  );
};


export default ConsumerHome;

export const ConsumerHomePageQuery = graphql`
  query ConsumerHomePage($slug: String!) {
      markdownRemark(fields: { slug: { eq: $slug} }) {
        frontmatter {
          title
          heroSectionTitle
          heroSectionDescription
          heroImage {
              childImageSharp {
                  gatsbyImageData (
                      placeholder: NONE
                      quality: 100
                  )
              }
          }
          heroImageAlt
          secondSectionTitle
          secondSectionSubTitle
          secondSectionImage {
            childImageSharp {
                gatsbyImageData (
                    placeholder: NONE
                    quality: 100
                )
            }
          }
          secondSectionImageAlt
          secondDescription
          secondSection {
            title
            description
            icon {
              childImageSharp {
                  gatsbyImageData (
                      placeholder: NONE
                      quality: 100
                      height: 65
                  )
              }
            }
            iconAlt
          }
          thirdSectionTitle
          thirdSectionSubTitle
          thirdSectionImage {
            childImageSharp {
                gatsbyImageData (
                    placeholder: NONE
                    quality: 100
                )
            }
          }
          thirdSectionImageAlt
          thirdSection {
            title
            description
            icon {
              childImageSharp {
                  gatsbyImageData (
                      placeholder: NONE
                      quality: 100
                      height: 60
                  )
              }
            }
            iconAlt
          }
        }           
      }   
  }
`;


import React from 'react';
import styled from 'styled-components';
import DynamicImage from '../../../atoms/DynamicImage';

const Background = styled.div`
    height: 1000px;
    width: 100%;
    background-color: #E6F1F4;
    margin-bottom: 270px;
    position: relative;
    z-index: -1;

    @media (max-width: 1200px) {
        margin-bottom: 400px;
    }
    @media (max-width: 950px) {
        margin-bottom: 500px;
    }
    @media (max-width: 769px) {
        margin-bottom: 900px;
    }
    @media (max-width: 500px) {
        margin-bottom: 1150px;
    }

`;

const Wrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    position: absolute;
    top: 0px;
    right: 0;
    left: 0;
    margin: auto;
    margin-bottom: 200px;
    
    @media (max-width: 1250px) {
        max-width: none;
        width: 100%;
    }
    @media (max-width: 769px) {
        padding: 0px 0 20px 0;
    }

    @media (max-width: 500px) {
        padding-top: 0;
    }
`;

const FlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    align-items: center;

    @media (min-width: 920px) and (max-width: 1250px) {
        align-items: end;
    }
    @media (max-width: 1200px) {
        max-width: none;
        width: 100%;
        margin: 0 auto;
    }

    @media (max-width: 769px) {
        flex-direction: column;
    }
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 100px 30px 20px 0;
    font-weight: 900;
    width: 60%;

    @media (max-width: 1250px) {
        margin: 0 20px;
    }

    @media (max-width: 850px) {
        width: 55%;
    }

    @media (max-width: 769px) {
        padding: 50px 20px 0 20px;
        width: 100%;
        text-align: center;
        margin: 0 auto;
    }

    @media (max-width: 500px) {
        width: 100%;
        padding: 30px 20px 0 20px;
    }
`;

const Title = styled.h1`
    font-size: 45px;
    color: #00536D;
    margin: -10px 0 0 0;
    max-width: 810px;
    font-weight: 900;
    line-height: 1.2;

    @media (max-width: 1200px) {
        width: 100%;
    }

    @media (max-width: 769px) {
        text-align: center;
        margin: 0 auto;
    }
    @media (max-width: 550px) {
        line-height: 1.3;
        font-size: 35px;
        margin-bottom: 10px;
    }
`;

const SubTitle = styled.h3`
    font-size: 18px;    
    font-weight: 600;
    color: #5F99AC;
    line-height: 1.5;
    margin-bottom: 20px;
    max-width: 500px;

    @media (max-width: 1200px) {
        width: 80%;
    }

    @media (max-width: 769px) {
        text-align: center;
        width: 100%;
        margin: 0 auto;
        color: #00536D;
    }
    @media (max-width: 550px) {
        margin-bottom: 0;
        line-height: 1.8;
    }
`;


const Description = styled.h3`
    font-size: 28px;    
    font-weight: 900;
    color: #00536D;
    line-height: 1.5;
    margin-bottom: 60px;
    text-align: right;
    padding: 0 35px;

    @media (max-width: 769px) {
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
        margin-top: 430px;
        padding: 0 20px;
    }
    @media (max-width: 550px) {
        margin-bottom: 30px;
        line-height: 1.8;
        margin-top: 350px;
    }
`;

const HeroImageContainer = styled.div`
    z-index: -1;
    position: absolute;
    width: 500px;
    right: 0;
    top: 40px;

    @media (max-width: 1250px) {
        width: 400px;
        right: 20px;
    }

    @media (max-width: 870px) {
        width: 350px;
    }
    @media (max-width: 769px) {
        top: 370px;
        width: 70%;
        right: 0;
        left: 0;
        margin: auto;
    }
    @media (max-width: 690px) {
        top: 460px;
    }
    @media (max-width: 650px) {
        top: 450px;
    }
    @media (max-width: 600px) {
        top: 440px;
    }
    @media (max-width: 500px) {
        top: 460px;
    }
    @media (max-width: 450px) {
        top: 550px;
    }
    @media (max-width: 350px) {
        top: 620px;
    }
`; 

const PointContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 700px;
    background: #FAFAFB;
    padding: 60px 50px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    z-index: 2;

    @media (max-width: 1200px) {
        width: 97%;
        margin: 0 auto;
        height: 700px;
    }
    @media (max-width: 950px) {
        height: 800px;
    }
    @media (max-width: 769px) {
        height: 900px;
    }
    @media (max-width: 600px) {
        height: 1000px;
    }
    @media (max-width: 500px) {
        height: 1250px;
    }
    
`;


const Point = styled.div`
    width: 45%;
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    text-align: left;

    @media (max-width: 600px) {
        width: 100%;
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    align-items: end;
    margin-bottom: 15px;
`;

const PointTitle = styled.h4`
    color: #5C97AA;
    font-size: 22px;
    margin: 0 0 -5px 0;
`;

const PointDescription = styled.p`
    color: #5C97AA;
    font-size: 18px;
`;


const IconContainer = styled.div`
    width: 65px;
    margin-right: 15px;
`;

const ThirdSection = ({ 
    title,
    subTitle,
    image,
    imageAlt,
    description,
    sectionPoints
}) => {
    return (  
        <div style={{position: 'relative'}}> 
        <Background />
            <Wrapper>
                <FlexContainer>
                    <HeroImageContainer>
                        <DynamicImage
                            src={image}
                            alt={imageAlt}
                        />
                    </HeroImageContainer>

                    <TextContainer>
                        <Title>{ title }</Title>
                        <SubTitle dangerouslySetInnerHTML={{ __html: subTitle }} />
                    </TextContainer>
                                    
                </FlexContainer>

                <Description>{ description }</Description>
                <PointContainer>
                    {sectionPoints.map((point, index) => {
                        return (
                            <Point>
                                <HeaderContainer>
                                    <IconContainer>
                                        <DynamicImage
                                            src={point.icon}
                                            alt={point.iconAlt}
                                        />
                                    </IconContainer>
                                    <PointTitle>{point.title}</PointTitle>
                                </HeaderContainer>
                                <PointDescription>{point.description}</PointDescription>
                            </Point>
                        )
                    })}

                </PointContainer>
            </Wrapper>
            </div>
    );
};

export default ThirdSection;

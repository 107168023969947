const FaqContent = [
    {
        title: "When will I see my balance updated after I make my first payment?",
        content: "",
        setOpen: false
    },
    {
        title: "How long will it take for my reduced debt to bring up my credit score?",
        content: "",
        setOpen: false
    },
    {
        title: "What happens if I miss a payment?",
        content: "Our self-serve, personalized options are ready when you are. Choose what works best for you and your individual finances and decided when you’re ready to start.",
        setOpen: true
    },
    {
        title: "When will I see my balance updated after I make my first payment?", 
        content: "",
        setOpen: false
    },
    {
        title: "How long will it take for my reduced debt to bring up my credit score?", 
        content: "",
        setOpen: false
    },
];

export default FaqContent;
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons/faChevronCircleRight';
import { faChevronCircleUp } from '@fortawesome/free-solid-svg-icons/faChevronCircleUp';
import './FaqButton.scss';

function AccordionItem(props) {
    const [opened, setOpened] = useState(props.data.setOpen);

    const toggleOpen = () => {
        setOpened(!opened);
    };

    const renderIcon = () => {
        if (opened) {
            return <FontAwesomeIcon icon={faChevronCircleUp} style={{color: '#A4C8DF'}} alt="Arrow icon pointing up" />;
        } else {
            return <FontAwesomeIcon icon={faChevronCircleRight} style={{color: '#0F516E'}} alt="Arrow icon pointing right" />;
        }
    };

    return (
        <>
        {props.index < 7 ?
        
            <div
                {...{
                    className: `accordion-item, ${opened &&
                        'accordion-item--opened'}`
                }}
                onClick={toggleOpen}
            >
                <div className="question_wrapper">
                    <div className="accordion-item__line">
                        <h3 className="accordion__question">
                            {props.data.title}
                        </h3>
                        <div className="accordion-item__icon">{renderIcon()}</div>
                    </div>
                </div>
                {opened ? 
                <div className="accordion-item__inner">
                    <div className="accordion-item__content">
                        <p className="accordion-item__paragraph"                 
                            dangerouslySetInnerHTML={{ __html: props.data.content }}
                        />
                    </div>
                </div>
                : null}
            </div>

        : 
        <>
        {props.showExtraQuestions ?
            <>
            <div
                {...{
                    className: `accordion-item, ${opened &&
                        'accordion-item--opened'}`
                }}
                onClick={toggleOpen}
            >
                <div className="question_wrapper">
                    <div className="accordion-item__line">
                        <h3 className="accordion__question">
                            {props.data.title}
                        </h3>
                        <div className="accordion-item__icon">{renderIcon()}</div>
                    </div>
                </div>
                {opened ? 
                <div className="accordion-item__inner">
                    <div className="accordion-item__content">
                        <p className="accordion-item__paragraph"                 
                            dangerouslySetInnerHTML={{ __html: props.data.content }}
                        />
                    </div>
                </div>
                : null}
            </div>
            </>
        : null} 
        </>
        }
        </>
    );
}

export default AccordionItem;

import React from 'react';
import styled from 'styled-components';
import DynamicImage from '../../../atoms/DynamicImage';
import Cta from '../../../atoms/CTA';

const Container = styled.div`
    position: relative;

`;

const Wrapper = styled.div`
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    

    @media (max-width: 1250px) {
        max-width: none;
        width: 100%;
    }
    @media (max-width: 769px) {
        padding: 0px 0 20px 0;
    }

    @media (max-width: 500px) {
        padding-top: 0;
    }
`;

const FlexContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    align-items: center;

    @media (max-width: 1200px) {
        max-width: none;
        width: 100%;
        margin: 0 auto;
        padding: 30px 0 50px 0;
        align-items: center;
    }

    @media (max-width: 950px) {
        padding: 0 0 80px 0;
    }
    @media (max-width: 769px) {
        flex-direction: column;
    }
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 200px 0 190px 0;
    font-weight: 900;
    width: 100%;

    .greenText {
        color: #27A04B;
    }

    .blueText {
        color: #10526E;
    }
    @media (max-width: 1250px) {
        padding: 150px 20px 0 25px;
        flex-basis: 100%;
    }
    
    @media (max-width: 769px) {
        padding: 100px 20px 0 20px;
        text-align: center;
        align-items: center;
    }

    @media (max-width: 500px) {
        padding: 110px 15px 0 15px;
        width: 100%;
    }
`;

const Title = styled.h1`
    font-size: 55px;
    color: #0F516E;
    margin: -10px 0 0 0;
    max-width: 454px;
    line-height: 1.4;
    font-weight: 900;

    @media (max-width: 1200px) {
        width: 100%;
        font-size: 50px;
    }

    @media (max-width: 950px) {
        font-size: 40px;
    }

    @media (max-width: 769px) {
        text-align: center;
        margin: 0 auto;
        line-height: 1.1;
        font-size: 50px;
    }
    @media (max-width: 550px) {
        font-size: 38px;
        line-height: 1.3;
    }
    @media (max-width: 400px) {
        font-size: 30px;
    }
`;

const Description = styled.h3`
    font-size: 18px;    
    font-weight: 600;
    color: #6DA3B4;
    line-height: 1.5;
    margin-bottom: 20px;
    max-width: 450px;

    @media (max-width: 1200px) {
        width: 100%;
    }

    @media (max-width: 769px) {
        text-align: center;
        width: 100%;
        margin: 30px auto 0 auto;
    }
    @media (max-width: 550px) {
        margin-bottom: 0;
        line-height: 1.8;
    }
`;


const HeroImageContainer = styled.div`
    max-width: 618px;
    padding-top: 50px;

    position: absolute;
    right: 0px;


    @media(max-width: 1250px) {
        max-width: none;
        width: 80%;
        position: relative;
    }
    @media (max-width: 769px) {
        padding-top: 10px;
        margin: 0 auto;
        width: 90%;
    }
`; 

const CtaContainer = styled.div`
    width: 200px;
    margin-top: 20px;

    @media (max-width: 769px) {
        margin: 30px auto 0px auto;
    }
`;

const HeroSection = ({ 
    title,
    paragraph, 
    src,
    heroImage,
    heroImageAlt
}) => {
    return (   
        <Container>
            <Wrapper>
                <FlexContainer>
                    <TextContainer>
                        <Title>{ title }</Title>
                        <Description>{ paragraph }</Description> 
                        <CtaContainer>
                            <Cta href="/contact-us">Let's Get Started</Cta>
                        </CtaContainer>
                    </TextContainer>

                    <HeroImageContainer>
                        <DynamicImage
                            src={heroImage}
                            alt={heroImageAlt}
                        />
                    </HeroImageContainer>
                </FlexContainer>
            </Wrapper>
        </Container>
    );
};

export default HeroSection;
